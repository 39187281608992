<template>
  <div>
    <v-row class="mt-3 justify-center">
      <v-col class="col-11 col-lg-9">
        <ResultsComponent
          :quiz="quiz"
          :credits="credits"
          :article-specialties="[]"
          :can-change-answers="false"
          :enable-go-to-feed="false"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
const ResultsComponent = () => import(/* webpackChunkName: "ResultsComponent" */ '@/components/quiz/ResultsComponent')

import { mapActions } from 'vuex';
export default {
  components: { ResultsComponent },
  data: () => ({
    quiz: [],
    credits: 0,
  }),
  methods: {
    ...mapActions('transcripts', ['getTranscriptsQuizResults']),
  },
  mounted() {
    this.getTranscriptsQuizResults({ params: { id: this.$route.params.id }}).then( response => {
      this.quiz = response.data.results;
      this.credits = response.data.credits;
    }).catch( error => {
      console.log(error);
    });
  }
}
</script>
